import React from 'react';
import { handleAuthentication } from 'utils/auth';
import { useQueryParam, StringParam } from 'use-query-params';

const Callback = () => {
  handleAuthentication();
  const [r, setR] = useQueryParam('r', StringParam);
  //   navigate(r || '/');
  return <p>Loading...</p>;
};

export default Callback;
